import { useTranslation } from "@/i18n/client";
import { Box, SxProps, Typography } from "@mui/material";
import { SyntheticEvent } from "react";
import ImageCustom from "../Image/ImageCustom";
import color from "@/styles/color";

interface ILeasingDetailProps {
  image: string;
  downPrice: string;
  textSx?: SxProps;
  containerSx?: SxProps;
  iconSize?: string;
  handleTooltipOpen?: React.MouseEventHandler<HTMLButtonElement>;
  handleTooltipClose?: (
    event: MouseEvent | TouchEvent | Event | SyntheticEvent<Element, Event>,
  ) => void;
  openTooltip?: boolean;
  isToyotaLeasing: boolean;
  isDownPriceMoreThanZero: boolean;
}

export const LeasingDetail = ({
  image,
  downPrice,
  textSx = {},
  containerSx = {},
  isToyotaLeasing,
  isDownPriceMoreThanZero = true
}: ILeasingDetailProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        padding: "8.5px 0px",
        ...containerSx,
      }}
    >
      <ImageCustom
        imageSrc={image ?? ""}
        imageAlt="Leasing Logo"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "40px",
          img: {
            objectFit: "contain !important",
            maxWidth: "unset",
            width: `${isToyotaLeasing ? "60px" : "50px"} !important`,
            height: `${isToyotaLeasing ? "40px" : "29px"} !important`,
          },
        }}
      />
      <Box sx={{ display: "flex", gap: "2px", alignItems: "center", justifyContent: "center" }}>
      {isDownPriceMoreThanZero ? (
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "10px",
            lineHeight: { xs: "23px" },
            color: color.gray400,
            ...textSx,
            paddingRight: "5px",
          }}
        >
          {downPrice} {`${t("common.THB")}*`}
        </Typography>
        ) : (
          <Typography
          sx={{
            fontWeight: 700,
            fontSize: "10px",
            lineHeight: { xs: "23px" },
            color: color.gray400,
            ...textSx,
            paddingRight: "5px",
          }}
        >
          {"-"}
        </Typography>
        )}
        {/* <Box sx={{ display: "flex", gap: "2px", alignItems: "center", justifyContent: "center" }}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Tooltip..."
          >
            <Button
              onClick={handleTooltipOpen}
              sx={{
                display: "flex",
                gap: "2px",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "0px",
                padding: "0px",
                width: "30px",
                height: "30px",
                position: "absolute",
              }}
            >
              <IconLoader
                iconName="NoticeIcon"
                sx={{ fontSize: iconSize, color: color.yellow100 }}
              />
            </Button>
          </Tooltip>
        </Box> */}
      </Box>
    </Box>
  );
};
