"use client";
import color from "@/styles/color";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import React, { useMemo } from "react";
import CheckboxWTB from "../Checkbox/CheckboxWTB";
import IconLoader from "../IconLoader";

interface IButtonCheckboxProps {
  label: string;
  boxContainerSx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  isActive?: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

function ButtonCheckbox({
  label,
  onClick,
  isActive = false,
  boxContainerSx = {},
  labelSx = {},
}: IButtonCheckboxProps) {
  const checked = useMemo(() => {
    return isActive;
  }, [isActive]);

  return (
    <Box
      role="button"
      aria-label={label}
      sx={{
        width: "100%",
        display: "flex",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "30px",
        border: `1px solid ${color.gray900}`,
        height: { xs: "40px", sm: "40px", md: "40px", lg: "40px", xl: "40px" },
        padding: {
          xs: "16px 0px",
          sm: "16px 8px",
          lg: "16px 16px",
        },
        ...boxContainerSx,
      }}
      onClick={onClick}
    >
      <CheckboxWTB
        checked={checked}
        sx={{
          color: color.gray400,
          width: "14px",
          height: "14px",
        }}
        iconSize="17px"
        icon={
          <IconLoader iconName="UncheckedIcon" sx={{ fontSize: "16px", color: color.gray400 }} />
        }
      />
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: { xs: "12px", xl: "14px" },
          lineHeight: { xs: "23px", sm: "24px" },
          color: color.gray400,
          ...labelSx,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}

export default ButtonCheckbox;
