import { Dealer } from "@/libs/types/dealer.type";
import { VehicleCard, VehicleDetail } from "@/libs/types/vehicle.type";
import { create } from "zustand";

import { persist } from "zustand/middleware";

type CompareCarStore = {
  checkCompareCarSelected: (id: number) => boolean;

  compareCarSelectedList: VehicleCard[];
  setCompareCarSelected: (carSelected: VehicleCard) => void;

  compareCarDetail: VehicleDetail[];
  setCompareCarDetail: (carSelectedList: VehicleDetail[]) => void;

  dealerDetail: Dealer[];
  setDealerDetail: (dealerList: Dealer[]) => void;

  showDrawer: boolean;
  setShowDrawer: (showDrawer: boolean) => void;

  showFloatingButton: boolean;
  setShowFloatingButton: (showFloatingButton: boolean) => void;

  showAlertLimitModal: boolean;
  setShowAlertLimitModal: (showAlertLimitModal: boolean) => void;

  removeCompareCarSelected: (id: number) => void;
  resetCompareCarSelected: () => void;
};

const initialState = {
  compareCarSelectedList: [],
  compareCarDetail: [],
  dealerDetail: [],
  showDrawer: false,
  showFloatingButton: false,
  showAlertLimitModal: false,
};

export const useCompareCarStore = create<CompareCarStore>()(
  persist(
    (set, get) => ({
      checkCompareCarSelected: (id: number) => {
        const carSelectedList = get().compareCarSelectedList;
        return !!carSelectedList.find((carSelected) => carSelected.VehicleMasterId === id);
      },

      compareCarSelectedList: initialState.compareCarSelectedList,
      setCompareCarSelected: (carSelected: VehicleCard) => {
        const { checkCompareCarSelected, compareCarSelectedList } = get();
        const isSelected = checkCompareCarSelected(carSelected.VehicleMasterId);

        if (!isSelected && compareCarSelectedList.length === 3) {
          set({ showAlertLimitModal: true });
          return;
        }

        const updated = isSelected
          ? compareCarSelectedList.filter(
              (item) => item.VehicleMasterId !== carSelected.VehicleMasterId,
            )
          : [...compareCarSelectedList, carSelected];

        set({ compareCarSelectedList: updated, showFloatingButton: updated.length > 0 });
      },

      compareCarDetail: initialState?.compareCarDetail,
      setCompareCarDetail: (compareCarDetail: VehicleDetail[]) => {
        set({ compareCarDetail });
      },

      dealerDetail: initialState?.dealerDetail,
      setDealerDetail: (dealerDetail: Dealer[]) => {
        set({ dealerDetail });
      },

      showDrawer: initialState.showDrawer,
      setShowDrawer: (showDrawer: boolean) => {
        set({ showDrawer });
      },

      showFloatingButton: initialState.showFloatingButton,
      setShowFloatingButton: (showFloatingButton: boolean) => {
        set({ showFloatingButton });
      },

      showAlertLimitModal: initialState.showAlertLimitModal,
      setShowAlertLimitModal: (showAlertLimitModal: boolean) => {
        set({ showAlertLimitModal });
      },

      removeCompareCarSelected: (id: number) => {
        const { compareCarSelectedList } = get();
        const { compareCarDetail } = get();
        const updated = compareCarSelectedList.filter((item) => item.VehicleMasterId !== id);
        const updatedDetail = compareCarDetail.filter((item) => item.VehicleMasterId !== id);

        set({
          compareCarSelectedList: updated,
          showFloatingButton: updated.length > 0,
          compareCarDetail: updatedDetail,
        });
      },

      resetCompareCarSelected: () => {
        set({
          compareCarSelectedList: initialState.compareCarSelectedList,
          showFloatingButton: initialState.showFloatingButton,
        });
      },
    }),
    {
      name: "compare-car",
      partialize: (state) => ({
        compareCarSelectedList: state.compareCarSelectedList,
        showFloatingButton: state.showFloatingButton,
      }),
    },
  ),
);
