import { FC } from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import color from "@/styles/color";
import IconButtonLightWeightCustom from "../Button/IconButtonLightWeightCustom";
import ReactPlayerCustom from "../Video/ReactPlayer";

type TModalVehicleVideoProps = {
  isOpen: boolean;
  url: string;
  onClose: () => void;
};

const ModalVehicleVideo: FC<TModalVehicleVideoProps> = ({ isOpen, url, onClose }) => {
  return (
    <Dialog
      aria-labelledby="modal-vehicle-video-title"
      aria-describedby="modal-vehicle-video-description"
      open={isOpen}
      onClose={(_, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") return;
        onClose();
      }}
      // scroll="body"
      sx={{
        height: "100%",
        maxHeight: "100dvh",
        overflow: "hidden",
        "& .MuiPaper-root": {
          bgcolor: "transparent",
          margin: "8px",
          boxShadow: "unset",
          width: { xs: "100%" },
          maxWidth: { xs: "960px" },
          height: "100%",
        },
        "& .MuiModal-backdrop": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "0px",
          backgroundColor: "transparent",
        }}
      >
        <Box
          sx={{
            height: "100%",
            position: "relative",
          }}
        >
          <IconButtonLightWeightCustom
            aria-label="close-modal"
            sx={{
              position: "absolute",
              top: "5px",
              right: "5px",
              zIndex: 100,
              width: "60px",
              height: "60px",
              background: color.redGradient,
              ":hover": { background: color.redGradient, opacity: 0.9 },
            }}
            onClick={onClose}
          >
            <CloseRoundedIcon
              sx={{
                fontSize: "40px",
                color: color.white,
              }}
            />
          </IconButtonLightWeightCustom>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              padding: { sm: "30px" },
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                bgcolor: color.white,
                borderRadius: "1rem",
                display: "flex",
                flexDirection: "column",
                padding: { sm: "32px 24px" },
                gap: 2,
              }}
            >
              <ReactPlayerCustom url={url} />
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalVehicleVideo;
