"use client";

import { useTranslation } from "@/i18n/client";
import { Box, Paper, Stack, SxProps, Typography } from "@mui/material";
import React, { FC, Fragment, useCallback, useMemo, useState } from "react";
import { Trans } from "react-i18next";

import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { thousandComma } from "@/libs/common/string";
import { VehicleCard } from "@/libs/types/vehicle.type";
import { generateVehicleUrlParams, getVehiclePrice } from "@/libs/utils/vehicle";
import color from "@/styles/color";
import ButtonCheckbox from "../Button/ButtonCheckbox";
import ButtonCustom from "../Button/ButtonCustom";
import ChipCustom from "../Chip/ChipCustom";
import { DividerCustom } from "../Divider/DividerCustom";
import ImageCustom from "../Image/ImageCustom";

import LineLogoImg from "@/assets/image/line-logo.webp";
import MessengerLogoImg from "@/assets/image/messenger-logo.webp";
import ToyotaLogoImg from "@/assets/image/toyota-sure-main-logo.webp";
import { useCompareCarStore } from "@/contexts/useCompareCar";
import { TSocialKey, useDealerStore } from "@/contexts/useDealer";
import { PagePath } from "@/libs/constant/path";
import { IconNameFilePathKey } from "@/libs/enum/icon-file-path.enum";
import IconLoader from "../IconLoader";
import ModalVehicleVideo from "../Modal/ModalVehicleVideo";
import Link from "next/link";
import { LeasingDetail } from "./LeasingDetail";
import { _startCase } from "@/libs/utils/helper";

type VehicleSuggestionCardProps = {
  data: VehicleCard;
  isWishlist: boolean;
  onClickWishlist: (vehiclesId: number) => Promise<void>;
};

const VehicleSuggestionCard: FC<VehicleSuggestionCardProps> = ({
  data,
  isWishlist,
  onClickWishlist,
}) => {
  const { isLessThanSm, isLessThanMd } = useReadScreen();
  const { t } = useTranslation();
  const { checkCompareCarSelected, setCompareCarSelected } = useCompareCarStore();
  const { setDealerInfo, setOpenDroplead, setVehicleId } = useDealerStore();
  const [isShowVideo, setIsShowVideo] = useState<boolean>(false);

  const vehicleImage = useMemo(() => {
    const imageUrl =
      data?.VehicleImageURL && data?.VehicleImageURL !== ""
        ? data.VehicleImageURL
        : ToyotaLogoImg.src;
    return imageUrl;
  }, [data]);

  const { carPrice, carPriceWithVat } = useMemo(() => {
    const { PromotionPrice, PromotionPriceWithVAT, ResellingPrice, ResellingPriceWithVAT } = data;
    return getVehiclePrice({
      PromotionPrice,
      PromotionPriceWithVAT,
      ResellingPrice,
      ResellingPriceWithVAT,
    });
  }, [data]);

  const vehicleDetail = useMemo(() => {
    const { AverageMileage, EngineCapacity, Gear, Mileage, Year } = data;

    return [
      {
        key: "province",
        icon: isLessThanSm ? "LocationGradientIcon" : "LocationIcon",
        value: data.Province,
        containerStyles: {
          flex: { xs: "0 100%", sm: "1 calc(50% - 2px)", xl: "0 calc(38% - 2px)" },
          background: { xs: color.white, sm: color.redGradient },
          border: { xs: "1px solid #E1E1E1", sm: "unset" },
          "& .MuiSvgIcon-root": {
            color: { sm: color.white },
          },
        },
        labelStyles: { color: { xs: color.gray400, sm: color.white }, textAlign: "center" },
      },
      {
        key: "year",
        icon: "CalendarGradientIcon",
        value: t("Vehicle.card.year").replace("{{year}}", `${Year}`),
        containerStyles: {
          flex: { xs: "1 calc(50% - 2px)", xl: "0 calc(29% - 2px)" },
        },
      },
      {
        key: "engine",
        icon: "PumpGradientIcon",
        value: t("Vehicle.card.engine").replace("{{engine}}", `${thousandComma(EngineCapacity)}`),
        containerStyles: {
          flex: { xs: "1 calc(50% - 2px)", xl: "0 calc(29% - 2px)" },
        },
      },
      {
        key: "transmission",
        icon: "GearGradientIcon",
        value: t("Vehicle.card.transmission").replace("{{transmission}}", Gear),
        containerStyles: {
          flex: { xs: "1 100%", sm: "1 calc(50% - 2px)", xl: "0 calc(32% - 2px)" },
        },
      },
      {
        key: "mileage",
        icon: "MileGradientIcon",
        value: t(`Vehicle.mileage-average`)
          .replace("{{mileage}}", `${thousandComma(Mileage)}`)
          .replace("{{average}}", `${AverageMileage <= 0 ? "-" : thousandComma(AverageMileage)}`),
        containerStyles: {
          flex: { xs: "1 100%", xl: "0 calc(68% - 2px)" },
        },
        labelStyles: {
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitLineClamp: 1,
          maxHeight: "4.5em",
          minHeight: "15.4px",
        },
      },
    ] as {
      key: string;
      icon: string;
      value: string;
      containerStyles: SxProps;
      labelStyles?: SxProps;
    }[];
  }, [data, isLessThanSm, t]);

  const installmentAmount = useMemo(() => {
    const finance = (data?.FinanceInfo || []).map((_finance) => _finance.MonthlyInstallment);
    return Math.min(...finance);
  }, [data]);

  const carDetailURL = useMemo(() => {
    const { Brand, Model, VehicleMasterId } = data;
    const path = generateVehicleUrlParams([{ Brand, Model, VehicleMasterId }]);
    return `${PagePath.vehicleDetail}/${path}`;
  }, [data]);

  const onClickSocial = useCallback((social: TSocialKey) => {
    setDealerInfo(social, data.VehicleMasterId, NaN);
  }, []);

  const onClickCompareCar = useCallback(() => {
    setCompareCarSelected(data);
  }, [data, setCompareCarSelected]);

  const [openTooltip, setOpenTooltip] = React.useState<string | null>(null);

  const handleTooltipOpen = (id: string) => {
    if (openTooltip === id) {
      setOpenTooltip(null);
    } else {
      setOpenTooltip(id);
    }
  };

  const handleTooltipClose = () => {
    setOpenTooltip(null);
  };

  return (
    <>
      <Paper
        sx={{
          boxShadow: "0px 4px 4px 0px #0000001A",
          width: "100%",
          position: "relative",
          borderRadius: "16px",
          cursor: "pointer",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        elevation={0}
        // onClick={onClickCard}
        component={Link}
        href={carDetailURL}
      >
        {data && (
          <>
            <Box
              sx={{
                position: "relative",
                width: "100%",
              }}
            >
              <ImageCustom
                imageSrc={vehicleImage}
                imageAlt="Car Thumbnail"
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "auto",
                  aspectRatio: { xs: 167 / 108, sm: 246 / 160, lg: 295 / 200 },
                }}
                options={{
                  style: {
                    borderRadius: "16px 16px 0px 0px",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              />
              {data?.VehicleVideoURL && (
                <Box
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsShowVideo(true);
                  }}
                  sx={{
                    position: "absolute",
                    width: { xs: "24px", sm: "38px" },
                    height: { xs: "24px", sm: "38px" },
                    borderRadius: "100%",
                    background: color.redGradient,
                    top: "12px",
                    left: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    ":hover": {
                      opacity: 0.7,
                    },
                  }}
                >
                  <IconLoader
                    iconName="VideoIcon"
                    sx={{
                      fontSize: { xs: "16px", sm: "16px", xl: "20px" },
                      color: color.white,
                    }}
                  />
                </Box>
              )}
              <Box
                component={"div"}
                sx={{
                  position: "absolute",
                  width: { xs: "40px", sm: "42px", md: "42px", lg: "41px", xl: "40px" },
                  height: { xs: "40px", sm: "40px", md: "40px", lg: "40px", xl: "40px" },
                  borderRadius: "100%",
                  bgcolor: color.white,
                  bottom: "10px",
                  right: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  ":hover": {
                    opacity: 0.7,
                  },
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClickWishlist(data.VehicleMasterId);
                }}
              >
                <IconLoader
                  iconName={isWishlist ? "HearthGradientIcon" : "HearthOutlineIcon"}
                  sx={{
                    fontSize: { xs: "18px", sm: "20px", md: "20px", lg: "20px", xl: "18px" },
                    color: color.black,
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                // minHeight: { xs: "565px", sm: "580px", md: "610px", lg: "615px", xl: "540px" },
                flex: 1,
                padding: { xs: "8px", sm: "10px" },
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: color.black,
                      fontSize: { xs: "12px", sm: "14px" },
                      lineHeight: { xs: "18px", md: "20px", lg: "21px" },
                    }}
                  >
                    {`${_startCase(data?.Brand)} ${data?.Model}`}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: color.gray300,
                      fontSize: { xs: "10px", sm: "12px" },
                      lineHeight: { xs: "15px", md: "18px" },
                    }}
                  >
                    {data?.Grade}
                  </Typography>
                </Stack>
                {data.ClassImageURL && (
                  <ImageCustom
                    imageSrc={data.ClassImageURL}
                    imageAlt="Toyota Certified"
                    sx={{
                      position: "relative",
                      width: {
                        xs: "120px",
                        // lg: "272px",
                      },
                      height: "auto",
                      aspectRatio: { xs: "320/149" }, //lg: "272/94",
                      img: {
                        aspectRatio: { xs: "320/149" },
                      },
                    }}
                    options={{
                      style: {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      },
                    }}
                  />
                )}
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  gap: "4px",
                  rowGap: { xs: "4px", xl: "8px" },
                  paddingTop: { xs: "4px", md: "8px" },
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {vehicleDetail.map((detail) => {
                  return (
                    <ChipCustom
                      key={`chip-label-${detail.key}`}
                      label={detail.value}
                      icon={
                        <IconLoader
                          iconName={detail.icon as IconNameFilePathKey}
                          sx={{
                            fontSize: "12px",
                          }}
                        />
                      }
                      containerStyles={{
                        width: "100%",
                        justifyContent: "center",
                        paddingX: "0px",
                        ...detail.containerStyles,
                      }}
                      labelStyles={{
                        color: color.gray400,
                        marginTop: "2px",
                        textAlign: "center",
                        ...detail.labelStyles,
                      }}
                    />
                  );
                })}
              </Box>
              <DividerCustom
                sx={{
                  "&.MuiDivider-root": {
                    margin: {
                      xs: "10px 0px",
                    },
                    borderColor: color.gray600,
                  },
                }}
              />
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: {
                    xs: "column-reverse",
                    xl: "row",
                  },
                  gap: { xs: "10px", xl: "8px" },
                }}
              >
                <Box
                  component={"div"}
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    flexWrap: "wrap",
                    columnGap: "4px",
                    rowGap: "4px",
                    width: "100%",
                  }}
                >
                  <Box
                    component={"div"}
                    sx={{
                      display: { xs: "none", sm: "flex" },
                      columnGap: "4px",
                      rowGap: "4px",
                      width: { xs: "unset", sm: "100%" },
                    }}
                  >
                    <Box
                      component={"div"}
                      sx={{
                        order: 1,
                        width: "100%",
                      }}
                    >
                      <ChipCustom
                        label={t("Vehicle.card.line")}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onClickSocial("line");
                        }}
                        icon={
                          <ImageCustom
                            imageSrc={LineLogoImg.src}
                            imageAlt="Line Logo"
                            sx={{
                              img: {
                                borderRadius: "100%",
                                objectFit: "contain",
                                maxWidth: "unset",
                                width: {
                                  xs: "16px !important",
                                },
                                height: {
                                  xs: "16px !important",
                                },
                              },
                            }}
                          />
                        }
                        containerStyles={{
                          width: { xs: "100%" },
                          height: { xs: "40px", sm: "32px", xl: "23px" },
                          justifyContent: "center",
                          border: `1px solid ${color.gray700}`,
                          cursor: "pointer",
                        }}
                        labelStyles={{
                          color: color.black,
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: { xs: "10px", sm: "14px", xl: "10px" },
                          lineHeight: { xs: "23px" },
                        }}
                      />
                    </Box>
                    <Box
                      component={"div"}
                      sx={{
                        order: 1,
                        width: "100%",
                      }}
                    >
                      <ChipCustom
                        label={t("Vehicle.card.chat")}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onClickSocial("facebook");
                        }}
                        icon={
                          <ImageCustom
                            imageSrc={MessengerLogoImg.src}
                            imageAlt="Messenger Logo"
                            sx={{
                              img: {
                                objectFit: "contain",
                                maxWidth: "unset",
                                width: {
                                  xs: "16px !important",
                                },
                                height: {
                                  xs: "16px !important",
                                },
                              },
                            }}
                            options={{
                              sizes: "10vw",
                            }}
                          />
                        }
                        containerStyles={{
                          width: { xs: "100%" },
                          height: { xs: "40px", sm: "32px", xl: "23px" },
                          justifyContent: "center",
                          border: `1px solid ${color.gray700}`,
                          cursor: "pointer",
                        }}
                        labelStyles={{
                          color: color.black,
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: { xs: "10px", sm: "14px", xl: "10px" },
                          lineHeight: { xs: "23px" },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    component={"div"}
                    sx={{
                      order: 1,
                      width: "100%",
                      flex: "1 1 auto",
                    }}
                  >
                    <ChipCustom
                      label={t("Vehicle.card.phone")}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onClickSocial("telephone");
                      }}
                      icon={
                        <IconLoader
                          iconName="TelephoneIcon"
                          sx={{
                            fontSize: { xs: "24px", sm: "20px", xl: "14px" },
                            color: { xs: color.gray700, xl: color.black },
                          }}
                        />
                      }
                      containerStyles={{
                        width: { xs: "100%" },
                        height: { xs: "40px", sm: "32px", xl: "23px" },
                        justifyContent: "center",
                        border: `1px solid ${color.gray700}`,
                        cursor: "pointer",
                      }}
                      labelStyles={{
                        color: color.black,
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: { xs: "10px", sm: "14px", xl: "10px" },
                        lineHeight: { xs: "23px" },
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  component={"div"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "flex-end",
                    alignContent: "flex-end",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: "20px" },
                      lineHeight: { xs: "30px", sm: "24px" },
                      background: color.redGradient,
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      textAlign: { xs: "center" },
                      textWrap: { xs: "nowrap" },
                    }}
                  >
                    <Trans i18nKey="common.price.amount" amount={carPrice}>
                      {{ amount: thousandComma(carPrice) }}
                    </Trans>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: { xs: "10px" },
                      lineHeight: { xs: "15px", sm: "20px", lg: "24px" },
                      color: color.black,
                      opacity: 0.5,
                      textAlign: { xs: "center" },
                    }}
                  >
                    <Trans i18nKey="common.price.amount-with-vat" amount={carPriceWithVat}>
                      {{ amount: thousandComma(carPriceWithVat) }}
                    </Trans>
                  </Typography>
                </Box>
              </Box>
              <DividerCustom
                sx={{
                  "&.MuiDivider-root": {
                    margin: "10px 0px",
                    borderColor: color.gray600,
                  },
                }}
              />
              <Box
                sx={{
                  display: { xs: "flex", sm: "none" },
                  flexDirection: { xs: "column" },
                  gap: "4px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: "12px", sm: "12px" },
                    lineHeight: { xs: "18px", sm: "18px" },
                    color: color.black,
                    textAlign: "center",
                  }}
                >
                  <Trans
                    i18nKey="Vehicle.card.installment"
                    components={{
                      amount: (
                        <span
                          style={{
                            background: color.redGradient,
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                          }}
                        />
                      ),
                    }}
                    values={{ amount: thousandComma(installmentAmount ?? 0) }}
                  />
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: "9px", sm: "9px" },
                    lineHeight: { xs: "13.5px", sm: "13.5px" },
                    color: color.black,
                    opacity: 0.5,
                    textWrap: "pretty",
                    textAlign: "center",
                  }}
                >
                  {t("Vehicle.card.installment-condition")}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: { xs: "none", sm: "flex" },
                  alignItems: "center",
                  flexDirection: { xs: "column", xl: "row" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { xs: "0px", xl: "4px" },
                    width: "100%",
                    padding: { xs: "8px 0px", xl: "0px" },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: { xs: "16px", sm: "10px" },
                      lineHeight: { xs: "20px", sm: "15px" },
                      color: color.black,
                    }}
                  >
                    {t("Vehicle.card.installment-start-title")}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: { xs: "9px", sm: "9px" },
                      lineHeight: { xs: "13.5px", sm: "13.5px" },
                      color: color.gray800,
                      textWrap: "pretty",
                      width: { xs: "100%", xl: "85%" },
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {t("Vehicle.card.installment-start-description")}
                    {/* <br />
                    {t("Vehicle.card.installment-start-description_vat")} */}
                  </Typography>
                </Box>
              </Box>
              <DividerCustom
                sx={{
                  "&.MuiDivider-root": {
                    margin: "10px 0px",
                    borderColor: color.gray600,
                  },
                }}
              />
              <Stack
                direction="row"
                divider={
                  <DividerCustom
                    orientation="vertical"
                    flexItem
                    sx={{
                      "&.MuiDivider-root": {
                        borderColor: color.gray600,
                      },
                    }}
                  />
                }
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
              >
                {data?.FinanceInfo?.map((finance, index) => {
                  const tooltipId = `Tooltip-${finance.FinanceId}-${index}`;
                  return (
                    <Fragment
                      key={`finance-info-${data.VehicleMasterId}-${finance.FinanceId}-${index}`}
                    >
                      <LeasingDetail
                        key={index + 1}
                        openTooltip={openTooltip === tooltipId}
                        handleTooltipOpen={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleTooltipOpen(tooltipId);
                        }}
                        handleTooltipClose={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleTooltipClose();
                        }}
                        image={finance.FinanceLogoURL}
                        downPrice={thousandComma(finance.MonthlyInstallment)}
                        isDownPriceMoreThanZero={Number(finance.MonthlyInstallment) > 0}
                        textSx={{
                          fontSize: { xs: "12px", sm: "10px", xl: "12px" },
                          lineHeight: { xs: "20px", sm: "23px" },
                        }}
                        containerSx={{
                          padding: { xs: "4.5px 0px", xl: "8.5px 0px" },
                        }}
                        iconSize="12px"
                        isToyotaLeasing={finance.FinanceName === "โตโยต้าลีสซิ่ง"}
                      />
                    </Fragment>
                  );
                })}
              </Stack>
              <DividerCustom
                sx={{
                  "&.MuiDivider-root": {
                    margin: "10px 0px",
                    borderColor: color.gray600,
                  },
                }}
              />
              <Box
                component={"div"}
                sx={{
                  display: { xs: "flex", sm: "none" },
                  paddingBottom: "10px",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <ChipCustom
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClickSocial("line");
                  }}
                  icon={
                    <ImageCustom
                      imageSrc={LineLogoImg.src}
                      imageAlt="Line Logo"
                      sx={{
                        img: {
                          borderRadius: "100%",
                          objectFit: "contain",
                          maxWidth: "unset",
                          width: {
                            xs: "24px !important",
                          },
                          height: {
                            xs: "24px !important",
                          },
                        },
                      }}
                    />
                  }
                  containerStyles={{
                    width: { xs: "40px" },
                    height: { xs: "40px" },
                    justifyContent: "center",
                    border: `1px solid ${color.gray700}`,
                    cursor: "pointer",
                  }}
                />
                <ChipCustom
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClickSocial("facebook");
                  }}
                  icon={
                    <ImageCustom
                      imageSrc={MessengerLogoImg.src}
                      imageAlt="Messenger Logo"
                      sx={{
                        img: {
                          objectFit: "contain",
                          maxWidth: "unset",
                          width: {
                            xs: "24px !important",
                          },
                          height: {
                            xs: "24px !important",
                          },
                        },
                      }}
                    />
                  }
                  containerStyles={{
                    width: { xs: "40px" },
                    height: { xs: "40px" },
                    justifyContent: "center",
                    border: `1px solid ${color.gray700}`,
                    cursor: "pointer",
                  }}
                />
                <ChipCustom
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClickSocial("telephone");
                  }}
                  icon={
                    <IconLoader
                      iconName="TelephoneIcon"
                      sx={{
                        fontSize: { xs: "24px" },
                        color: { xs: color.gray700 },
                      }}
                    />
                  }
                  containerStyles={{
                    width: { xs: "40px" },
                    height: { xs: "40px" },
                    justifyContent: "center",
                    border: `1px solid ${color.gray700}`,
                    cursor: "pointer",
                  }}
                />
              </Box>
              <Stack gap={1}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0px",
                    gap: "8px",
                  }}
                >
                  <ButtonCustom
                    label={t("Vehicle.card.request_dealer_to_contact_back")}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setOpenDroplead(true);
                      setVehicleId(data.VehicleMasterId);
                    }}
                  />
                </Box>
                <ButtonCheckbox
                  label={
                    isLessThanMd ? t("Vehicle.card.compare") : t("Vehicle.card.compare_full_text")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClickCompareCar();
                  }}
                  isActive={checkCompareCarSelected(data.VehicleMasterId)}
                />
              </Stack>
            </Box>
          </>
        )}
      </Paper>
      {data.VehicleVideoURL && (
        <ModalVehicleVideo
          url={data.VehicleVideoURL}
          isOpen={isShowVideo}
          onClose={() => setIsShowVideo(false)}
        />
      )}
    </>
  );
};

export default VehicleSuggestionCard;
