import { Box } from "@mui/material";
import { FC } from "react";

type TReactPlayerCustomProps = {
  url: string;
};

const ReactPlayerCustom: FC<TReactPlayerCustomProps> = ({ url }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: "100%",
        position: "relative",
      }}
    >
      <video
        muted
        autoPlay
        controls
        preload="none"
        controlsList="nodownload"
        style={{ 
          width: '100%', 
          height: '100%', 
          objectFit: 'contain', 
          aspectRatio: 342 / 236,
        }}
      >
        <source src={url} type="video/mp4" autoFocus />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};

export default ReactPlayerCustom;
