export const thousandComma = (input: number, decimal = 0) => {
  if (input == null) return "";
  const options: Intl.NumberFormatOptions = {
    style: "decimal",
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  };
  return input.toLocaleString("en-US", options);
};

export const removeHTMLTag = (html: string) => {
  return html
    .replace(/<\/?[^>]+(>|$)/g, "") // Remove HTML tags
    .replace(/&nbsp;/g, " ")        // Replace &nbsp; with a regular space
    .replace(/&[a-zA-Z]+;/g, "");   // Optionally remove other HTML entities
};

export const removeStyleFontFamily = (html: string) => {
  const fontFamilyRegex = /font-family:[^;"]+;?/g;
  return html.replace(fontFamilyRegex, ""); // Remove cms font-familiy
};
