import { DealerServices } from "@/libs/services/dealer";
import { create } from "zustand";

export type TSocialKey = "line" | "facebook" | "telephone";

export type DealerMobile = {
  name: string;
  mobile: string;
};

export type DealerLine = {
  offical: string;
  saleman: DealerSaleLine[];
};

export type DealerSaleLine = {
  name: string;
  lineId: string;
};

type DealerContact = {
  tel: DealerMobile[];
  line: DealerLine;
  facebookID: string
};

export type FilterDealerList = {
  DealerId?: string;
  RegionId?: string;
  ProvinceId?: string | number;
  SearchText?: string;
};

type DealerStore = {
  dealerInfo: DealerContact | null;
  currentType: TSocialKey | null;
  isOpenDroplead: boolean;
  vehicleId: number;
  setDealerInfo: (type: TSocialKey, VehicleMasterId: number, dealerId: number) => Promise<void>;
  clearDealerInfo: () => void;
  setVehicleId: (vehicleId: number) => void;
  setOpenDroplead: (isOpenDroplead: boolean) => void;
};

export const useDealerStore = create<DealerStore>((set) => ({
  isPersistFilterDealer: false,
  filterDelaerList: {
    DealerId: "",
    RegionId: "",
    ProvinceId: "",
    SearchText: "DealerMap",
  },
  vehicleId: NaN,
  dealerInfo: null,
  currentType: null,
  isOpenDroplead: false,
  setOpenDroplead: (isOpenDroplead: boolean) => set({ isOpenDroplead: isOpenDroplead }),
  setVehicleId: (vehicleId: number) => set({ vehicleId }),
  setDealerInfo: async (type: TSocialKey, VehicleMasterId: number, dealerId: number) => {
    const param = {};
    if (VehicleMasterId) Object.assign(param, { VehicleMasterId: VehicleMasterId.toString() });
    if (dealerId) Object.assign(param, { DealerId: dealerId.toString() });

    const dealer = await DealerServices.getDealerList(param);
    const _dealerInfo = dealer?.DealerInfo?.at(0) ?? null;

    if (!_dealerInfo) return;

    const contact = _dealerInfo.SalesmanInfo;
    const tels = contact.map((contct) => ({
      name: contct.SalesmanName,
      mobile: contct.SalesmanPhoneNo,
    }));
    const saleLineList = contact.map((contct) => ({
      name: contct.SalesmanName,
      lineId: contct.SalesmanLineNo,
    }));

    set({
      currentType: type,
      dealerInfo: {
        line: {
          offical: _dealerInfo.LineNo,
          saleman: saleLineList,
        },
        tel: tels,
        facebookID: _dealerInfo.FacebookId
      },
    });
  },
  clearDealerInfo: () => {
    set({ currentType: null, dealerInfo: null });
  },
}));
